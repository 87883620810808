import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Tasacion monedas",
  "image": "../../images/tasacion_monedas.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`¿Qué es la tasación de monedas antiguas?`}</h2>
    <p>{`La tasación de monedas determina el valor de la moneda y tiene un enfoque principalmente coleccionistas y numismáticos. Existen comunidades y profesionales que se encargan de estas valoraciones. Se suelen fijar muchos valores a través de comparación con subastas recientes y estas permiten elaborar catálogos de monedas para coleccionistas.`}</p>
    <h2>{`¿Por qué hay que tasar una moneda?`}</h2>
    <p>{`El principal motivo para tasar una moneda es para su venta a un coleccionista o para que el propio coleccionista conozca la valoración de su colección. `}</p>
    <h2>{`¿Cómo tasar una moneda antigua?`}</h2>
    <p>{`La forma de tasar una moneda antigua para colección es bastante polémica ya que no existen unos criterios objetivos establecidos. El mercado es el que a través de catálogos comerciales suele proporcionar unos valores que en ocasiones pueden no reflejar la realidad pero que si sirven como referencia. Actualmente es posible verificar algunas operaciones con monedas en portales de compra venta que permiten dar al propietario una idea de su valor por comparación, pero en ocasiones los estudios para la tasación de una moneda son muy complejos y nada sencillos para personas simplemente aficionadas.
Por lo tanto, si no puedes establecer una idea a partir de la información que se obtiene por internet la otra posibilidad es acudir a un profesional que nos puede hacer un informe con datos sobre la moneda y que el mismo informe puede aumentar el valor.
Otra forma más difícil para usuarios no aficionados a la numismática, pero posiblemente la mejor, es acudir a subastas para ver cómo se mueve el mercado actual.`}</p>
    <h2>{`¿Qué factores importan en la tasación de una moneda antigua?`}</h2>
    <p>{`Sabemos que valorar una moneda tiene una gran relatividad y no tiene unas directrices fijas, pero si podemos ver algunos de los criterios principales por lo que se basa una tasación.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Autenticidad`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Es fundamental que tu moneda no sea una imitación o replica.`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><strong parentName="li">{`Fecha acuñación`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Generalmente a mayor antigüedad mayor es el valor de la moneda.`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><strong parentName="li">{`País`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Según la historia del país de origen la moneda ya que existen periodos importantes en la historia de cada país que pueden proporcionar de más valor a una moneda que otra para la misma fecha, sobre todo, si hay algún acontecimiento histórico remarcable.`}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol"><strong parentName="li">{`Estado`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`El estado de conservación puede hacer que el valor de una moneda antigua cambie muchísimo, en ocasiones más del doble ya que es fundamental para algunos coleccionistas.`}</li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol"><strong parentName="li">{`Cantidad`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`La cantidad de monedas que hay iguales, es evidente que cuantas menos monedas del mismo tipo la moneda aumentará más su valor.`}</li>
    </ul>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol"><strong parentName="li">{`Demanda`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Como en todo mercado la demanda es otro factor importante.`}</li>
    </ul>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol"><strong parentName="li">{`Material`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Normalmente el valor del material se tiene en cuenta aunque existen algunas excepciones.`}</li>
    </ul>
    <h2>{`¿Tasar una moneda antigua online de forma gratuita?`}</h2>
    <p>{`Para tasar una moneda de forma gratuita te recomendamos que revises en foros y webs que tienen monedas en ventas y puedes comparar si está la tuya, si no lo más recomendado sería acudir a profesionales.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://finumas.es/119-catalogos-monedas-sellos-billetes"
        }}>{`finumas`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.monedalia.es/"
        }}>{`monedalia`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.numismaticamramos.com/"
        }}>{`numismaticamramos`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.catawiki.es/c/718-monedas-y-billetes"
        }}>{`catawiki`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ventippo.com"
        }}>{`ventippo`}</a></li>
    </ul>
    <h2>{`¿Dónde puedo vender monedas antiguas?`}</h2>
    <p>{`Puedes vender en internet las monedas antiguas que dispongas, para ello tienes que realizar una buena descripción de la moneda y acompañarla con una foto. Normalmente las monedas se suelen vender por lotes, pero es posible hacerlo individualmente incluso será más beneficioso para ti.
Por otro lado, existen algunos centros que compran monedas, aunque estos suelen solo comprar algunos modelos específicos y te darán menos valor del que posiblemente tengan.
Por último, una opción más compleja pero ideal si te estás aficionando a las monedas antiguas es ir a eventos numismáticos para subastarlas.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      